import React from "react";
import { Link, graphql } from "gatsby";
import MainLayout from "../layouts/main-layout";
import Title from "../components/atoms/title";
import { useTranslation } from "react-i18next";
import { IQueryAllResult } from "../models/query-all-result.model";
import { getNodes } from "../utils/get-nodes";
import ReactMarkdown from "react-markdown";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  container,
  linkedin as linkedinClass,
  linkedin_container,
  internalButton,
  img,
  link,
} from "./single-news.module.scss";
import Linkedin from "../assets/images/svg/linkedin.svg";
import Button from "../components/atoms/button";

interface ISignleNewsProps {
  readonly data: {
    allStrapiNews: IQueryAllResult<any>;
  };
}

const SingleNews = ({ data }: ISignleNewsProps) => {
  const { t } = useTranslation();
  const { allStrapiNews } = data;
  console.log(allStrapiNews);
  const newsData = getNodes(allStrapiNews)[0];
  const gatsbyImage = getImage(newsData?.photo?.localFile);
  return (
    <MainLayout titleSeo="News" descriptionSeo="News">
      <Title Tag={"h1"} size={"large"}>
        {newsData?.title}
      </Title>
      <div className={container}>
        <div>
          <ReactMarkdown>{newsData.description.data.description}</ReactMarkdown>
        </div>
        <div>
          {gatsbyImage && (
            <GatsbyImage alt="" className={img} image={gatsbyImage} />
          )}
        </div>
      </div>
      <div className={linkedin_container}>
        <Link to={newsData?.linkedin_link} target="_blank" className={link}>
          <div className={internalButton}>
            <p> {t("news.on.linkedin")}</p>
            <Linkedin className={linkedinClass} />{" "}
          </div>
        </Link>
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  query ($language: String!, $slug: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allStrapiNews(filter: { slug: { eq: $slug }, locale: { eq: $language } }) {
      edges {
        node {
          id
          slug
          title
          linkedin_link
          description {
            data {
              description
            }
          }
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

export default SingleNews;
